import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { NotasContext } from 'contexts/NotasContext';
import { GridContext } from 'contexts/GridContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { toastWarning } from 'utils/toast';
import { useModal, useDialog } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import PrevisãoModal from './Modals/PrevisãoModal';
import ImportaXMLModal from 'components/Modals/ImportaXMLModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Notas = () => {
  const rotina = 'NotasCompra';
  const titulo = 'Notas de Compra';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { postDocumentos } = useContext(DocumentosContext);
  const { exportPdf, exportXls, deleteGrid } = useContext(GridContext);
  const { notas, getNotas, getLoading, exportXML, exportXMLAll } =
    useContext(NotasContext);
  const { control, getValues, reset } = useForm({
    defaultValues: notas?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getNotas({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar Nota',
      icon: 'add',
      action: () => navigate('/app/Notas/Gerar/NF'),
    },
    {
      name: 'Adicionar Nota de Serviço',
      icon: 'add',
      action: () => navigate('/app/Notas/Gerar/NFS'),
    },
    {
      name: 'Adicionar Fatura',
      icon: 'add',
      action: () => navigate('/app/Notas/Gerar/Fatura'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: notas?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: notas?.order }),
    },
    {
      name: 'Exportar XML',
      icon: 'file_download',
      action: (selected) => {
        if (!Boolean(selected?.length)) {
          exportXMLAll({ data: { rotina, filter: getValues() }, cb: loadGrid });
        } else {
          exportXML({
            data: { notas: selected?.map((s) => s?.id) },
            cb: loadGrid,
          });
        }
      },
    },
    {
      name: 'Importar XML',
      icon: 'file_upload',
      action: () =>
        openDialog(
          <ImportaXMLModal callback={loadGrid} />,
          'Importação de Arquivos XML'
        ),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={notas?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Registrar Notas',
      icon: 'send',
      action: (selected) => {
        const notas = selected?.filter((f) =>
          Boolean(f?.status !== 'CONCLUIDO')
        );
        if (!Boolean(notas?.length)) {
          return toastWarning('Selecione apenas documentos não concluídos');
        } else {
          postDocumentos({
            data: selected?.map((s) => ({
              Documento: { id: s?.id, conferido: 'SIM' },
            })),
            cb: loadGrid,
          });
        }
      },
    },
    {
      name: 'Impressão de Etiquetas',
      icon: 'sell',
      action: (selected) => {
        if (Boolean(selected?.length)) {
          navigate('/app/Notas/Etiquetas', {
            state: { notas: selected?.map((s) => s?.id) },
          });
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Gerar MDFE',
      icon: 'post_add',
      action: (documentos) => {
        if (Boolean(documentos?.length)) {
          const check = Boolean(
            documentos?.every((s) => s?.status === 'CONCLUIDO')
          );
          if (check) {
            navigate('/app/Notas/Gerar/MDFE', { state: { documentos } });
          } else {
            toastWarning(
              'Selecione somente Notas Fiscais eletrônicas concluídas'
            );
          }
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
  ];
  const rowOptions = [
    {
      name: 'Aprovação necessária',
      icon: 'priority_high',
      color: 'secondary.main',
      show: ({ row }) => Boolean(row?.aprovacao),
    },
    {
      name: 'Baixar Previsão',
      icon: 'price_check',
      show: ({ row }) => Boolean(row?.previsoes),
      action: ({ row }) =>
        openDialog(
          <PrevisãoModal
            item={row}
            onSubmit={(params) =>
              deleteGrid({
                params,
                cb: () => {
                  loadGrid();
                  closeDialog();
                },
              })
            }
          />,
          'Baixar Previsão'
        ),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={notas?.colunas} />
      <Card>
        <Grid
          grid={notas}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={notas} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Notas;
