export const dropBoolean = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

export const dropStatusDocumento = [
  { value: 'CANCELADO', label: 'CANCELADO' },
  { value: 'CONCLUIDO', label: 'CONCLUÍDO' },
  { value: 'EXPEDICAO', label: 'EXPEDIÇÃO' },
  { value: 'FINALIZADO', label: 'FINALIZADO' },
  { value: 'PARALISADO', label: 'PARALISADO' },
  { value: 'PENDENTE', label: 'PENDENTE' },
];

export const dropFretePorConta = [
  {
    value: '0 - CONTRATACAO DO FRETE POR CONTA DO REMETENTE (CIF)',
    label: '0 - CONTRATACAO DO FRETE POR CONTA DO REMETENTE (CIF)',
  },
  {
    value: '1 - CONTRATACAO DO FRETE POR CONTA DO DESTINATARIO (FOB)',
    label: '1 - CONTRATACAO DO FRETE POR CONTA DO DESTINATARIO (FOB)',
  },
  {
    value: '2 - CONTRATACAO DO FRETE POR CONTA DE TERCEIROS',
    label: '2 - CONTRATACAO DO FRETE POR CONTA DE TERCEIROS',
  },
  {
    value: '3 - TRANSPORTE PROPRIO POR CONTA DO REMETENTE',
    label: '3 - TRANSPORTE PROPRIO POR CONTA DO REMETENTE',
  },
  {
    value: '4 - TRANSPORTE PROPRIO POR CONTA DO DESTINATARIO',
    label: '4 - TRANSPORTE PROPRIO POR CONTA DO DESTINATARIO',
  },
  {
    value: '9 - SEM OCORRENCIA DE TRANSPORTE',
    label: '9 - SEM OCORRENCIA DE TRANSPORTE',
  },
];

export const dropViaTransporteFrete = [
  { value: '01 - RODOVIARIO', label: '01 - RODOVIARIO' },
  { value: '02 - AEREO', label: '02 - AEREO' },
  { value: '03 - MARITIMO', label: '03 - MARITIMO' },
  { value: '04 - FERROVIARIO', label: '04 - FERROVIARIO' },
];

export const dropNaturezaFrete = [
  {
    value: '0 - OP.VENDAS COM ONUS SUPORTADO PELO ESTAB.VENDEDOR',
    label: '0 - OP.VENDAS COM ONUS SUPORTADO PELO ESTAB.VENDEDOR',
  },
  {
    value: '1 - OP.VENDAS COM ONUS SUPORTADO PELO ADQUIRENTE',
    label: '1 - OP.VENDAS COM ONUS SUPORTADO PELO ADQUIRENTE',
  },
  {
    value: '2 - OP.COMPRAS GERADORES DE CRÉDITO',
    label: '2 - OP.COMPRAS GERADORES DE CRÉDITO',
  },
  {
    value: '3 - OP.COMPRAS NÃO GERADORES DE CRÉDITO',
    label: '3 - OP.COMPRAS NÃO GERADORES DE CRÉDITO',
  },
  {
    value: '4 - TRANSF.PRD ACABADOS ENTRE ESTAB.DA P.JURÍDICA',
    label: '4 - TRANSF.PRD ACABADOS ENTRE ESTAB.DA P.JURÍDICA',
  },
  {
    value: '5 - TRANSF.PRD EM ELABORAÇÃO ENTRE ESTAB.DA P.JURÍDICA',
    label: '5 - TRANSF.PRD EM ELABORAÇÃO ENTRE ESTAB.DA P.JURÍDICA',
  },
  { value: '9 - OUTRAS', label: '9 - OUTRAS' },
];

export const dropIndPres = [
  {
    value: 0,
    label:
      '0 - Não se aplica (por exemplo, Nota Fiscal complementar ou de ajuste)',
  },
  { value: 1, label: '1 - Operação presencial' },
  { value: 2, label: '2 - Operação não presencial, pela Internet' },
  { value: 3, label: '3 - Operação não presencial, Tele atendimento' },
  { value: 4, label: '4 - NFC-e em operação com entrega a domicílio' },
  { value: 5, label: '5 - Operação presencial, fora do estabelecimento' },
  { value: 9, label: '9 - Operação não presencial, outros' },
];

export const dropTipoRateio = [
  { value: 'desconto_vlr', label: 'DESCONTO' },
  { value: 'frete', label: 'FRETE' },
  { value: 'seguro', label: 'SEGURO' },
  { value: 'despac', label: 'DESPESAS ACESSÓRIAS' },
  { value: 'outros', label: 'OUTROS' },
  { value: 'eventuais', label: 'EVENTUAIS' },
  { value: 'internacionalizacao', label: 'INTERNACIONALIZAÇÃO' },
  { value: 'fretend', label: 'FRETE NÃO DESTACADO' },
  { value: 'segurond', label: 'SEGURO NÃO DESTACADO' },
  { value: 'impostond', label: 'IMPOSTOS FEDERAIS NÃO DESTACADO' },
  { value: 'comissaond', label: 'COMISSÃO NÃO DESTACADO' },
];

export const dropNTributado = [
  { value: 'ISENTO', label: 'ISENTO' },
  { value: 'OUTROS', label: 'OUTROS' },
  { value: 'NENHUM', label: 'NENHUM' },
];

export const dropOperacao = [
  { value: 'ENTRADA', label: 'ENTRADA' },
  { value: 'SAIDA', label: 'SAÍDA' },
];

export const dropStatusDfe = [
  { value: 'AUTORIZADO', label: 'AUTORIZADO' },
  { value: 'CANCELADO', label: 'CANCELADO' },
  { value: 'DENEGADO', label: 'DENEGADO' },
];

export const dropXmlManifesto = [
  { value: 'IMPORTADO', label: 'IMPORTADO' },
  { value: 'IMPORTAR', label: 'DISPONÍVEL' },
  { value: 'INDISPONIVEL', label: 'INDISPONÍVEL' },
];

export const dropSituacaoManifesto = [
  { value: '210210', label: 'Ciência da Operação' },
  { value: '210200', label: 'Confirmação da Operação' },
  { value: '210220', label: 'Desconhecimento da Operação' },
  { value: '210240', label: 'Operação não Realizada' },
  { value: '0', label: 'Não Manifestado' },
];

export const dropTipoDoctoManifesto = [
  { value: 'NFe', label: 'NFe' },
  { value: 'CTe', label: 'CTe' },
];

export const dropTipoEmitente = [
  { value: '1', label: 'Prestador de serviço de transporte' },
  { value: '2', label: 'Transportador de carga própria' },
  { value: '3', label: 'Transportador com emissão de CT-e' },
];

export const dropTipoTransportador = [
  { value: '1', label: 'ETC' },
  { value: '2', label: 'TAC' },
  { value: '3', label: 'CTC' },
];
